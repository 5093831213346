import { EDateLimit } from '@/types/enum/DateLimitEnum'
import { type DetectionModel } from '@/types/models/DetectionModel'

export type DetectionFilters = DetectionModel & DetectionModel

export type AdvancedDetectionFilters = {
    start_at?: Date
    end_at?: Date
    filters?: {
        date_limit?: EDateLimit
    }
}

export const defaultAdvancedDetectionFilters = {
    filters: {
        date_limit: EDateLimit.FUTURE
    }
}
