<script setup lang="ts">
import DeviceTimerEvent from '@/components/DeviceComponents/Card/DeviceTimerEvent.vue'
import { type DeviceProviderType, EProviders } from '@rfc/rfc-components-library/types'
import { computed, provide } from 'vue'
import { useI18n } from 'vue-i18n'
import type { Columns } from '../../types/components/Columns'
import DeviceCreatePage from '../DeviceComponents/Create/DeviceCreateFields.vue'
import CheckpointLinkColumn from '../DeviceComponents/Table/CheckpointLinkColumn.vue'
import EventLinkColumn from '../DeviceComponents/Table/EventLinkColumn.vue'

const { t } = useI18n()

const columnsBefore = computed<Columns[]>(() => [
    { value: 'bib', title: t('detection.model.bib') },
    { value: 'event_name', title: t('detection.model.eventName') },
    { value: 'checkpoint_name', title: t('detection.model.checkpointName') }
])

const columnsAfter = computed<Columns[]>(() => [
    { value: 'event_filter', title: t('detection.model.eventFilter') },
    { value: 'timer_filter', title: t('detection.model.timerFilter') }
])

provide<DeviceProviderType>(EProviders.DEVICE, {
    DeviceCardHeader: DeviceTimerEvent,
    DeviceDetectionsColumns: {
        columnsBefore,
        columnsAfter,
        components: [
            {
                name: 'event_name',
                component: EventLinkColumn
            },
            {
                name: 'checkpoint_name',
                component: CheckpointLinkColumn
            }
        ]
    },
    DeviceCreateFields: DeviceCreatePage
})
</script>

<template>
    <slot />
</template>
