<script setup lang="ts">
import { EDeviceType } from '@/types/enum/DeviceTypeEnum'
import { type DeviceModel } from '@/types/models/DeviceModel'
import { NumberInputField, StackLayout } from '@rfc/rfc-components-library'
import { computed, ref, type ComputedRef } from 'vue'
import { useRoute } from 'vue-router'
import OrganizationSelector from '../../Selector/OrganizationSelector.vue'

const route = useRoute()

const organizationId = computed(() => route.params.id?.toString())

type Props = {
    data?: Partial<DeviceModel>
    errors?: ComputedRef<Record<string, boolean>>
    submitted?: ComputedRef<boolean>
}
const props = defineProps<Props>()
type Emits = {
    (e: 'change', value: Partial<DeviceModel>): void
}
defineEmits<Emits>()

const deviceTypeHasImeiSAT = computed(
    () =>
        props.data?.device_type_id === EDeviceType.NANO_SAT ||
        props.data?.device_type_id === EDeviceType.MODULE_SAT
)
const createErrors = ref({
    organizationError: (value: DeviceModel) => !value?.data?.organization_id
})
defineExpose({
    errors: createErrors
})
</script>

<template>
    <StackLayout direction="row" :gap="8">
        <OrganizationSelector
            v-if="!organizationId"
            required
            :error="submitted?.value && errors?.value.organizationError"
            :organizationId="data?.data?.organization_id as number"
            @select="$emit('change', { data: { organization_id: $event?.id as number } })"
            class="full-width" />

        <NumberInputField
            v-if="deviceTypeHasImeiSAT"
            :label="$t('device.model.imeiSAT')"
            :modelValue="data?.imei?.[1]"
            @change="$emit('change', { imei: [data?.imei?.[0] ?? undefined, $event] })"
            :error="submitted?.value && errors?.value.imeiSatError"
            required />
    </StackLayout>
</template>
