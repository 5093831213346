<script setup lang="ts">
import {
    EProviders,
    type DetectionProviderType,
    type ExtendedPrimitives
} from '@rfc/rfc-components-library/types'
import { formatTimestamp } from '@rfc/rfc-components-library/utils'
import { provide } from 'vue'

const generateTitle = (detection?: Record<string, ExtendedPrimitives>) =>
    `Bib ${detection?.bib}${detection?.event_filter ? ` - E${detection?.event_filter}` : ''} ${
        detection?.event_filter ? ` - ${detection?.event_filter}` : ''
    }`
const generateSubtitle = (detection?: Record<string, ExtendedPrimitives>) =>
    formatTimestamp(detection?.timestamp as Date | string)

provide<DetectionProviderType>(EProviders.DETECTION, {
    generateTitle,
    generateSubtitle
})
</script>

<template>
    <slot />
</template>
