<script setup lang="ts">
import { EProviders, type SocketProviderType } from '@rfc/rfc-components-library/types'
import { provide } from 'vue'

const socketProvider: SocketProviderType = {
    alertsSocket: { url: import.meta.env.VITE_SOCKET_URL, path: 'device' },
    detectionsSocket: { url: import.meta.env.VITE_SOCKET_URL, path: 'urtime-detections' }
}

provide<SocketProviderType>(EProviders.SOCKET, socketProvider)
</script>

<template>
    <slot />
</template>
