<script setup lang="ts">
import { DEVICE_ROUTES, EVENT_CHECKPOINT_ROUTES, EVENTS_ROUTES } from '@/router/routes'
import store from '@/store'
import { EStoreModules } from '@/store/storeType'
import { ELocalStorage } from '@/types/enum/LocalStorageEnum'
import { ESortBy, ESortByDate, getSortByKey } from '@/types/enum/SortByEnum'
import {
    type AdvancedDetectionFilters,
    type DetectionFilters
} from '@/types/filters/DetectionFilters'
import { type LocalStorageFilter } from '@/types/filters/DeviceFilters'
import type { DetectionModel } from '@/types/models/DetectionModel'
import type { AssociatedDeviceModel } from '@/types/models/DeviceModel'
import { type EventModel } from '@/types/models/EventModel'
import { DATETIME_FORMAT, formatTimestamp } from '@/utils/dateUtils'
import { getItemFromStorage } from '@/utils/localStorageUtils'
import { getQueryFilters, getQuerySort } from '@/utils/queryUtils'
import { isMobile, isMobileEvent } from '@/utils/viewsUtils'
import { ClickableTypography, FullTableLayout } from '@rfc/rfc-components-library'
import { EDetectionActions } from '@rfc/rfc-components-library/store'
import { type Columns, type SelectorObject } from '@rfc/rfc-components-library/types'
import moment from 'moment'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import ExportDetectionButton from '../../components/Buttons/ExportDetectionButton.vue'
import DetectionsDesktopAdvancedFilters from './DetectionsDesktopAdvancedFilters.vue'
import DetectionsDesktopFilters from './DetectionsDesktopFilters.vue'
import DetectionsMobileAdvancedFilters from './DetectionsMobileAdvancedFilters.vue'
import DetectionsMobileFilters from './DetectionsMobileFilters.vue'

const { t } = useI18n()

const data = computed(() => store.value?.state.detection)
const storeFilters = computed(() => store.value?.state.filters)
const selectedEvent = ref(getItemFromStorage<LocalStorageFilter>(ELocalStorage.FILTERS)?.event)

const filters = ref<Partial<AdvancedDetectionFilters & DetectionFilters>>({
    event_id: selectedEvent.value?.id ? parseInt(selectedEvent.value?.id.toString()) : undefined,
    ...getQueryFilters(),
    ...storeFilters.value
})
const sort = ref<ESortBy>(getQuerySort() ?? ESortBy.DATE_RECENT)

const getDetections = () =>
    store.value?.dispatch(`${EStoreModules.DETECTION}/${EDetectionActions.FETCH_DETECTIONS}`, {
        filters: filters.value,
        sort: getSortByKey(sort.value),
        per_page: data.value?.per_page,
        page: data.value?.current_page
    })

const columns = computed<Columns[]>(() => [
    { value: 'device_name', title: t('detection.table.deviceName') },
    { value: 'bib', title: t('detection.table.bib') },
    { value: 'event_filter', title: 'Event ID' },
    { value: 'timer_filter', title: 'Timer ID' },
    { value: 'checkpoint_name', title: t('detection.table.checkpointName') },
    { value: 'event_name', title: t('detection.table.eventName') },
    { value: 'timestamp', title: t('detection.model.timestamp') }
])

const handleChangePageSize = (value: number) => {
    if (data.value) data.value.per_page = value
    if (data.value) data.value.current_page = 1
    getDetections()
}

const handleChangePageNumber = (value: number) => {
    if (data.value) data.value.current_page = value
    getDetections()
}

const handleFiltersChange = (value: Partial<DetectionModel>) => {
    filters.value = { ...filters.value, ...value }
    if (data.value) data.value.current_page = 1
    getDetections()
}

const handleAdvancedFilters = (value: Partial<AdvancedDetectionFilters>) =>
    (advancedFilters.value = { ...advancedFilters.value, ...value })

const handleChangeSortBy = (sortBy: ESortBy) => {
    sort.value = sortBy
    getDetections()
}

const handleSearch = () => {
    filters.value = { ...filters.value, ...advancedFilters.value }
    if (data.value) data.value.current_page = 1
    getDetections()
}

const handleRowClick = (row: DetectionModel) => {
    selectedEvent.value = { id: row.event_id, name: row.event_name }
    filters.value = {
        ...filters.value,
        event_id: row.event_id,
        checkpoint_id: row.checkpoint_id,
        bib: row.bib,
        event_filter: row.event_filter,
        timer_filter: row.timer_filter
    }
    if (data.value) data.value.current_page = 1
    getDetections()
}

const showMobileFilters = ref(isMobile())
isMobileEvent(e => (showMobileFilters.value = e.matches))
const table = ref<any>([])

const deviceOptions = ref<AssociatedDeviceModel[]>()

const advancedFilters = ref<Partial<AdvancedDetectionFilters & DetectionFilters>>({
    ...getQueryFilters()
})
const filtersTags = computed(() =>
    Object.entries({
        start_at: filters.value.start_at
            ? moment(filters.value.start_at).utc().format(DATETIME_FORMAT.value).toString()
            : undefined,
        end_at: filters.value.end_at
            ? moment(filters.value.end_at).utc().format(DATETIME_FORMAT.value).toString()
            : undefined
    })
        .map(([name, value]) => ({
            name,
            value
        }))
        .filter(item => !!item.value)
)

const handleRemoveTag = (value: SelectorObject) => {
    filters.value = { ...filters.value, [value.name ?? 'name']: undefined }
    advancedFilters.value = { ...advancedFilters.value, [value.name ?? 'name']: undefined }
    if (data.value) data.value.current_page = 1
    getDetections()
}

onMounted(() => getDetections())
const handleSelectEvent = (event?: EventModel) => {
    selectedEvent.value = event
    handleFiltersChange({ event_id: event?.id })
}
</script>

<template>
    <FullTableLayout
        showAdvancedFilters
        :orderOptions="ESortByDate"
        :sortBy="sort"
        @search="handleSearch"
        @changeSortBy="handleChangeSortBy"
        :advancedFilters="filtersTags"
        translationPath="detection.filters"
        :columns="columns"
        :data="data"
        keyData="detections"
        :loading="data?.loading"
        @click:row="handleRowClick"
        @removeTag="handleRemoveTag"
        @change:pageSize="handleChangePageSize"
        @change:pageNumber="handleChangePageNumber">
        <template #export>
            <ExportDetectionButton
                :event="selectedEvent"
                :checkpointId="filters.checkpoint_id"
                :sort="sort"
                :perPage="data?.per_page"
                :currentPage="data?.current_page" />
        </template>
        <template #filters>
            <DetectionsMobileFilters
                v-if="showMobileFilters"
                :filters="filters"
                :selectedEvent="selectedEvent"
                :deviceOptions="deviceOptions"
                @change="handleFiltersChange"
                @associates="deviceOptions = $event"
                @selectEvent="handleSelectEvent" />
            <DetectionsDesktopFilters
                v-else
                :filters="filters"
                :selectedEvent="selectedEvent"
                @change="handleFiltersChange"
                @selectEvent="handleSelectEvent" />
        </template>

        <template #device_name="item">
            <ClickableTypography
                :label="item.device_name"
                :href="DEVICE_ROUTES.buildUrl(item.device_id)" />
        </template>
        <template #checkpoint_name="item">
            <ClickableTypography
                :label="item.checkpoint_name"
                :href="`${EVENT_CHECKPOINT_ROUTES.buildUrl(item.event_id)}/${
                    item.checkpoint_id
                }`" />
        </template>
        <template #event_name="item">
            <ClickableTypography
                :label="item.event_name"
                :href="EVENTS_ROUTES.buildUrl(item.event_id)" />
        </template>
        <template #timestamp="item">
            {{ formatTimestamp(item.timestamp) }}
        </template>

        <template #dialogContent>
            <DetectionsMobileAdvancedFilters
                v-if="showMobileFilters"
                :filters="advancedFilters"
                @change="handleAdvancedFilters" />
            <DetectionsDesktopAdvancedFilters
                v-else
                :filters="advancedFilters"
                @change="handleAdvancedFilters" />
        </template>
    </FullTableLayout>
</template>
