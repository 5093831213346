<script setup lang="ts">
import { StackLayout } from '@rfc/rfc-components-library'
import {
    EColors,
    EProviders,
    type ContextProviderType,
    type TabType
} from '@rfc/rfc-components-library/types'
import { addToQuery } from '@rfc/rfc-components-library/utils'
import { inject, ref } from 'vue'
import { type LocationQueryValue } from 'vue-router'
import TabItem from './TabItem.vue'

const { router, route } = inject<ContextProviderType>(EProviders.GLOBAL, {})

type Props = {
    tab?: number
    tabs: TabType[]
    multiline?: boolean
}

defineProps<Props>()
type Emits = {
    (e: 'change', value?: string | number | LocationQueryValue[]): void
}
defineEmits<Emits>()

const activeTab = ref<number | string | undefined>(
    route?.query?.tab ? parseInt(route.query?.tab?.toString()) : 0
)

const handleChange = (value: number) => {
    if (router) addToQuery(router, { tab: value ?? '0' })
    activeTab.value = value
}
</script>

<template>
    <StackLayout direction="column">
        <v-tabs
            v-if="tabs.length"
            :modelValue="activeTab"
            @update:modelValue="handleChange($event as number)"
            :color="EColors.PRIMARY"
            density="compact"
            fixed
            class="flex flex-column tabs-component"
            multiline
            v-bind="$attrs">
            <TabItem v-for="(item, key) in tabs" :key="key" :value="key" :label="item.label" />
        </v-tabs>
        <v-tabs-window v-model="activeTab">
            <v-tabs-window-item
                v-for="(item, key) in tabs"
                :key="key"
                :value="key"
                class="padding-y">
                <slot :name="item.id" />
            </v-tabs-window-item>
        </v-tabs-window>
    </StackLayout>
</template>

<style scoped lang="scss"></style>
