<script setup lang="ts">
import i18n from '@/locales/i18n'
import { EProviders, type ContextProviderType } from '@rfc/rfc-components-library/types'
import { provide } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import DetectionContextProvider from './DetectionContextProvider.vue'
import DeviceContextProvider from './DeviceContextProvider.vue'
import SocketContextProvider from './SocketContextProvider.vue'

const router = useRouter()
const route = useRoute()
const store = useStore()
const { t } = useI18n()

provide<ContextProviderType>(EProviders.GLOBAL, { router, route, store, t, i18n })
</script>

<template>
    <DeviceContextProvider>
        <DetectionContextProvider>
            <SocketContextProvider>
                <slot />
            </SocketContextProvider>
        </DetectionContextProvider>
    </DeviceContextProvider>
</template>
