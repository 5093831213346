import { EDateFieldLimit, EDateLimit } from '@/types/enum/DateLimitEnum'
import { type EventModel } from '@/types/models/EventModel'

export type EventFilters = Partial<EventModel>
export type EventLocalStorageFilters = {
    event_id?: number
    checkpoint_id?: number
}

export const defaultEventFilters: EventFilters = {
    start_at: undefined,
    end_at: undefined
}

export type DateLimitFilters = {
    period: EDateLimit
    field: EDateFieldLimit.START_AT | EDateFieldLimit.END_AT
}

export type AdvancedEventFilters = {
    finished?: 0 | 1
    ongoing?: 0 | 1
    upcoming?: 0 | 1
}

export const defaultAdvancedEventFilters: AdvancedEventFilters = {
    ongoing: 1,
    upcoming: 1
}
