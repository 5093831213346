import authenticationRoutes from '@/router/routing/authenticationRoutes'
import detectionsRoutes from '@/router/routing/detectionsRoutes'
import eventsRoutes from '@/router/routing/eventsRoutes'
import homeRoutes from '@/router/routing/homeRoutes'
import organizationRoutes from '@/router/routing/organizationRouting/organizationRouting'
import supportFAQRoutes from '@/router/routing/supportFAQRoutes'
import { adminRouting, devicesRoutes } from '@rfc/rfc-components-library/router'

import accountRoutes from '@/router/routing/accountRoutes'
import store from '@/store'
import { EStoreModules } from '@/store/storeType'
import { type UserDataModel } from '@/types/models/UserModel'
import { getItemFromStorage } from '@/utils/localStorageUtils'
import { DashboardLayout } from '@rfc/rfc-components-library'
import { EAuthActions, EAuthMutations } from '@rfc/rfc-components-library/store'
import { getCurrentThemeMode } from '@rfc/rfc-components-library/utils'
import moment from 'moment'
import { createRouter, createWebHistory, type Router } from 'vue-router'
import { menuRoutesTop } from './menuRoutes'

const darkModeLogo = new URL('@/assets/logo-dark.png', import.meta.url).href
const lightModeLogo = new URL('@/assets/logo-light.png', import.meta.url).href

export const BASE_URL = '/'
export const LOGIN_URL = '/login'
export const HOME_URL = '/home'

const router: Router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        ...authenticationRoutes,
        {
            path: BASE_URL,
            component: DashboardLayout,
            props: {
                menuLogoProps: {
                    logo: {
                        darkModeLogo,
                        lightModeLogo
                    }
                },
                menuAccountProps: {
                    themeProps: {
                        currentTheme: getCurrentThemeMode.value
                    }
                },
                routes: menuRoutesTop
            },
            meta: {
                title: ''
            },
            children: [
                ...homeRoutes,
                ...organizationRoutes,
                ...devicesRoutes,
                ...detectionsRoutes,
                ...eventsRoutes,
                ...adminRouting,
                ...supportFAQRoutes,
                ...accountRoutes
            ]
        },
        {
            path: '/:catchAll(.*)',
            name: 'catchPage',
            redirect: '/'
        }
    ]
})

router.beforeEach(async (to, from, next) => {
    // redirect to login page if the user is not logged yet

    /* console.log({ store: store.value })
    return next() */
    const user = getItemFromStorage<UserDataModel>('user')

    if (!store.value?.state?.authentication?.loggedIn && user)
        moment().isAfter(user.expires_at)
            ? store.value?.dispatch(`${EStoreModules.AUTHENTICATION}/${EAuthActions.LOGOUT}`)
            : store.value?.commit(
                  `${EStoreModules.AUTHENTICATION}/${EAuthMutations.LOGIN_SUCCESS}`,
                  store.value?.state?.authentication?.accessToken
              )

    const loggedIn = store.value?.state.authentication.loggedIn

    if (to.meta.authRequired && !loggedIn) return next('/login')
    if (to.meta.guestRoute && loggedIn) return next('/')
    if (
        (!to.meta.permission && !to.meta.permissions) ||
        (to.meta.permissions as string[])?.find(item => user?.permissions?.includes(item)) ||
        user?.permissions?.includes(to.meta.permission as string)
    )
        return next()
    return next(from)
})

router.afterEach(async to => (document.title = to.meta.title ? to.meta.title : 'UrTime Hub'))

export default router
